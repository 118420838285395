$(document).on('turbolinks:load', function() {
    $('form.new_control_image button.cc-upload-button').on('click', function(e) {
        e.preventDefault();
        var fileInput = $(e.currentTarget).parents("form").find("input[type=file]");
        fileInput.click();
    });

    $('form.new_control_image input[type=file]').on('change', function(e) {
        $(e.currentTarget).parents("form").submit();
    });
});